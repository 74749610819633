import * as React from "react";
import PageBody from "../components/PageBody";
import H2 from "../components/Headings/H2";
import Paragraph from "../components/Paragraph";

import Layout from "../components/Layout";
import { createSeoData } from "../utils/page";

export default function TermsPage() {
  const seo = createSeoData({
    title: "Terms &#38; Conditions",
    description:
      "Read the terms and conditions of using Lingua Fonica to learn languages online.",
  });
  const hero = {
    heroTitleLine1: "Terms",
    heroTitleLine2: "and conditions",
  };

  return (
    <Layout seo={seo} hero={hero}>
      <PageBody limitMaxWidth>
        <H2 title="Terms and Conditions for Lingua Fonica" />
        <H2 subtitle="1. Introduction" subtitleAlignLeft />
        <Paragraph>
          Please read these terms and conditions carefully before using the
          linguafonica.com website, mobile app, and language learning platform
          operated by Lingua Fonica, LLC (hereafter “Company”). Your access to
          and use of these services is conditioned upon your acceptance of and
          compliance with these terms. These terms apply to all visitors, users,
          and others who access or use these services (hereafter “User”). Users
          are further divided into “Tutors” (who teach the lessons) and
          “students” (who learn the lessons). It is possible for a User to be
          both a Tutor and a Student. By accessing or using the Services, you
          agree to be bound by these Terms. If you disagree with any part of the
          Terms, then you may not access the Service.
        </Paragraph>
        <H2
          subtitle="2. Right to make changes to the agreement"
          subtitleAlignLeft
        />
        <Paragraph>
          The Company hereby reserves the right to amend the Terms and
          Conditions without User’s consent for any reason including compliance
          with legal requirements, notices, regulations, or other guidance of
          general application. Updated Terms will be posted on our Website with
          indication of the modification date at the top of the page. By
          continuing to use the Services following amendment of these Terms, you
          consent to be bound by the Terms as amended.
        </Paragraph>
        <H2
          subtitle="3. User guidelines (rules, restrictions, requirements)"
          subtitleAlignLeft
        />
        <Paragraph>
          While using the Services, you agree that you will comply with all
          applicable laws, including, without limitation, privacy laws,
          intellectual property laws, anti-spam laws, tax laws, etc. You also
          agree that you will provide accurate information to us and keep it
          updated. Any use of the Website that Lingua Fonica, in its sole
          discretion, finds inappropriate and/or offensive may result in
          suspension and/or termination of the User’s access with or without
          notice. While using the Services, you agree that you shall not disrupt
          or otherwise interfere in any way with any other User&apos;s use of
          the Services. You agree to abide by the following standards of
          conduct. You will not and will not authorize or facilitate any attempt
          by another person to use our Website to: a. Transmit any Content that
          is unlawful, harmful, threatening, abusive, harassing, defamatory,
          vulgar, offensive, obscene, pornographic, lewd, lascivious, or
          otherwise objectionable, as determined by the Company; b. Circumvent
          or manipulate our fee structure or billing process; c. Use a name or
          language that the Company, in its sole discretion, deems offensive; d.
          Post false, inaccurate, misleading, defamatory, or libelous content
          (including personal data); e. Post hateful or racially or ethnically
          objectionable content; f. Post Content which infringes another&apos;s
          copyright, trademark or trade secret; g. Advertise or offer to sell
          any goods or services for any commercial purpose through the Service;
          h. Post unsolicited advertising or unlawfully promote products or
          services; i. Take any action that may undermine the feedback or
          ratings system when it becomes available or using it for purposes
          unrelated to the Company; j. Harass, threaten or intentionally
          embarrass or cause distress to another user, person or entity. k.
          Impersonate another person; l. Promote, solicit, or participate in any
          multi-level marketing or pyramid schemes; m. Exploit children under 18
          years of age; n. Engage in disruptive activity such as sending
          multiple messages; o. Adapt, alter, license, sublicense or translate
          the Service for your own personal or commercial use; p. Introduce
          viruses, worms, Trojan horses and/or harmful code to the Website; q.
          Obtain unauthorized access to any computer system through the Website;
          r. Transfer your Lingua Fonica account (including feedback) and Login
          Credentials to another party without our consent; s. Harvest or
          otherwise collect information about Users, including email addresses,
          without their consent; t. Invade the privacy of any person, including
          but not limited to posting personally identifying or otherwise private
          information about a person without their consent (or their parent’s
          consent in the case of a child under 18 years of age); u. Solicit
          personal data from children under 18 years of age; v. Violate any
          federal, state, local, or international law or regulation; w.
          Encourage conduct that would constitute a criminal or civil offense;
          x. Work with other users (students or tutors) outside of the Company’s
          Service for at least 1 year after termination of the relationship of
          both parties with the Company; You will be punctual and professional
          in your dress and interactions with Company and Users, including
          Staff, Tutors, and Students; Users must be 18 years and older to be
          able to form a legally binding contracts under applicable law.
          Individuals under the age of 18 can use our Services only in
          conjunction with and under the supervision of a Parent. In this case,
          the Parent is responsible for any and all activities of the Student;
          You acknowledge and agree that, from time to time, the Company may
          record audio and video of your lessons for any reason, including
          quality and training purposes. By using the Company’s services, you
          agree and give consent for such recordings that is gratuitous,
          unsolicited, and without restriction. You agree that the Company may
          use these recordings without fiduciary, or other obligation, and
          without any additional compensation. Students and tutors may NOT
          record any lessons or share any copyrighted material.
        </Paragraph>
        <H2
          subtitle="4. Copyright and intellectual property"
          subtitleAlignLeft
        />
        <Paragraph>
          The term “copyright” shall have the meaning assigned to that term in
          the Copyright Act of 1976, 17 U.S.C. § 101. “Intellectual Property”
          means any and all tangible and intangible and now known or hereafter
          existing (i) rights associated with works of authorship throughout the
          world, including copyrights, moral rights (to the extent permitted by
          applicable law), and mask-works; (ii) registered and unregistered
          trademark, service mark, trade dress, and trade name rights and
          similar rights; (iii) trade secret rights; (iv) patents, designs,
          algorithms and other industrial property rights; (v) personality
          rights and rights of publicity; and (vi) all other intellectual and
          proprietary rights (of every kind and nature throughout the world and
          however designated) (including logos, character rights, moral rights
          (to the extent permitted by applicable law), and “rental” rights),
          whether arising by operation of law, contract, license or otherwise;
          (vii) all registrations, applications, renewals, extensions,
          continuations, divisions or reissues thereof (including rights in any
          of the foregoing); and (viii) any business and technical information
          including computer source code, program codes, patent applications,
          inventions, improvements, developments, discoveries, know-how,
          confidential and proprietary information and trade secrets, computer
          hardware or software products, processes, techniques, writings,
          drawings, visual or other graphic material, and trademark and service
          mark applications and any and all rights thereto, including, without
          limitation, the right to sue for infringement, the right to
          registration, and the right to legal protection of the foregoing.
          INTELLECTUAL PROPERTY RIGHTS All intellectual property in the design
          and layout of this Website, Software and the material and information
          published on its pages belongs to and is vested in Lingua Fonica, LLC
          or its licensors. You may not copy any part of this Website or
          otherwise do anything in relation to any part of the Website. You may
          not otherwise use or reproduce any of this Website or the material
          contained within it in any manner other than those listed above
          without first obtaining the prior written permission of the Company.
          Unless otherwise noted, all Content contained on this Website is the
          property of the Company and/or its affiliates or licensors, and is
          protected from unauthorized copying and dissemination by United States
          copyright law, trademark law, international conventions and other
          intellectual property laws. The service marks and trademarks of the
          Company, including without limitation Lingua Fonica logos are service
          marks owned by Lingua Fonica. Any other trademarks, service marks,
          logos and/or trade names appearing via the Service are the property of
          their respective owners. You may not copy or use any of these marks,
          logos or trade names without the express prior written consent of the
          owner. The Company grants you permission (which may be revoked at any
          time for any reason or no reason) to use the Website and the Software
          for the Services as provided herein and in accordance with these Terms
          and solely for your own personal, non-commercial use (except as
          provided herein), provided you do not remove any trademark, copyright
          or other notice contained on such pages. No other use is permitted.
          The Company reserves all of its statutory and common law rights
          against any person or entity who violates this paragraph. You may not
          link or frame to any pages of the Website or any content contained
          therein, whether in whole or in part, without prior written consent
          from the Company. You may like or follow the Company or share links to
          the Website via social networking technology referenced on the
          Website. Any rights not expressly granted herein are reserved. Except
          as expressly authorized by the Company or by Content providers, you
          agree not to reproduce, modify, rent, lease, loan, sell, distribute,
          mirror, frame, republish, download, transmit, or create derivative
          works of the Content of others, in whole or in part, by any means. You
          must not remove or modify any copyright or trademark notice, or other
          notice of ownership. Tutors retain ownership of their own
          copyrightable works unless the work is a “work-for-hire,” as that term
          is defined under federal law, or is subject to a separate written
          agreement that requires assignment to the Company or to a third party.
          In the case of assignment to the Company, the Tutor shall retain the
          right to use the material for noncommercial purposes. Payment Terms
          Student credit cards will be charged when they purchase lessons. Their
          account will be credited with the number of hours they have paid in
          advance. The Student’s account will be debited 24 hours before the
          scheduled lesson. Alternatively, Students can elect to be charged on a
          recurring basis 24 hours before each lesson. Tutors will be paid
          bi-weekly for lessons completed during the time period that ended two
          weeks before or in the next time period after they reach the $100
          minimum payment threshold.
        </Paragraph>
        <H2 subtitle="5. Governing law" subtitleAlignLeft />
        <Paragraph>United States, Ohio</Paragraph>
        <H2 subtitle="6. Limitation of liability" subtitleAlignLeft />
        <Paragraph>
          Tutors are independent contractors and not employees of the Company.
          Tutor agrees and represents, and Company agrees, as follows: a) Tutor
          has the right to perform services for others during the term of this
          Agreement; b) There is no obligation for the Company to provide hours;
          c) there is no obligation for the Tutor to accept any lesson contract;
          d) The Tutor confirms that they have the equipment and connectivity
          necessary to teach effectively in a remote format; e) The Tutor shall
          not be required to wear any uniforms provided by the Company; f) The
          services required by this Agreement shall be performed by the Tutor;
          g) The Tutor shall not receive any training from the Company in the
          professional skills necessary to perform the services required by this
          Agreement; h) The Tutor shall not be required by Company to devote
          full time to the performance of the services required by this
          Agreement; i) Tutor shall be responsible for all expenses, equipment,
          tools, and materials incurred while performing services under this
          Agreement. This includes any expenses including travel expenses;
          license fees and permits; insurance premiums; taxes; fines; computer,
          Internet, or phone expenses; meals; and any other expenses necessary
          to complete the work under this Agreement; j) In consideration for the
          services to be performed by Tutor, the Company agrees to pay Tutor at
          the rates agreed upon and updated on the Tutor’s profile page on the
          website; k) Tutor represents and warrants that they will comply with
          all federal, state, and local laws required to carry out the services
          to be performed under this Agreement; l) Company will not withhold
          FICA (Social Security and Medicare taxes) from Contractor&apos;s
          payments or make FICA payments on Tutor&apos;s behalf; m) Company will
          not make state or federal unemployment compensation contributions on
          Tutor&apos;s behalf and Tutor will not be entitled to these benefits
          in connection with work performed under this Agreement.; n) Company
          will not withhold state or federal income tax from Tutor&apos;s
          payments; o) Tutor shall pay all taxes incurred while performing
          services under this Agreement—including all applicable income taxes
          and, if Tutor is not a corporation, self-employment (Social Security)
          taxes. Upon demand, Tutor shall provide the Company with proof that
          such payments have been made; p) Tutor understands that they are not
          eligible to participate in any employee pension, health, vacation pay,
          sick pay, or other fringe benefit plan of Company.
        </Paragraph>
        <H2
          subtitle="7. Termination of Accounts or Service"
          subtitleAlignLeft
        />
        <Paragraph>
          The Company may terminate any User’s access to our Website in our sole
          discretion, for any reason and at any time, with or without prior
          notice. It is our policy to terminate Users who violate these Terms,
          as deemed appropriate in our sole discretion. We may terminate a
          Tutor’s use of the Services (a) immediately for failure to comply with
          the Terms; (b) for other cause, including, but not limited to, sexual
          or other unwelcome harassment, threats or intimidation, fraud,
          falsification of documents or qualifications; or (c) upon 14 days’
          advance written notice for any reason. If a student complains about a
          tutor, that tutor will be given feedback and the opportunity to
          improve. If the improvement is deemed unsatisfactory by the Company,
          that tutor will be removed from the list of available tutors effective
          immediately. Students who are disrespectful or uncooperative will be
          given feedback and the opportunity to improve. If the student’s
          attitude and commitment to these terms and conditions does not improve
          to the satisfaction of the Company, their contract will be terminated
          and they will forfeit any credit on their account. They will not be
          permitted to take lessons with the Company again for a minimum of six
          months. If issues between a Tutor and a Student proves unresolvable,
          the student may switch to a new tutor. The original tutor will be paid
          for the lessons taught, but their contract with that student will be
          ended and they will receive no further compensation from that
          contract. If the student decides to discontinue due to dissatisfaction
          with the second tutor, they will be refunded the remaining balance on
          their account minus a $25 non-refundable fee.
        </Paragraph>
        <H2
          subtitle="8. Late Cancellation and Refund Policy"
          subtitleAlignLeft
        />
        <Paragraph>
          If a Tutor cancels or reschedules a lesson with less than 24
          hours-notice, without uncontrollable circumstances, that tutor will
          not be able to claim payment the next time a student cancels within 24
          hours and may be subject to additional penalties such as an additional
          fifteen minutes added on to a future lesson. If a Student cancels or
          reschedules a lesson with less than 24 hours-notice, the student will
          be charged for that lesson and the tutor will be paid their usual
          rate. If a student shows up late for a lesson, they forfeit that time
          and the class will end as scheduled. If a tutor shows up late for a
          lesson, they must make up that time, either by ending the lesson later
          if convenient for the student or by scheduling a block of time that is
          convenient for the student. If a student is unhappy after the first
          lesson, they can get a refund for any remaining prepaid lessons. If a
          tutor is unable to continue a contract and a suitable replacement
          cannot be found, Lingua Fonica will issue a refund for remaining
          credits. Credits do not expire, but if a student fails to use their
          credits for a period of one-year after payment, the credits are
          non-refundable.
        </Paragraph>
        <H2
          subtitle="9. Disclaimer of Warranties and Limitation of Liability"
          subtitleAlignLeft
        />
        <Paragraph>
          To the full extent permissible by law, Lingua Fonica disclaims all
          warranties, express or implied, including, but not limited to, implied
          warranties of merchantability and fitness for a particular purpose.
          Lingua Fonica does not warrant that the Lingua Fonica services,
          information, content, materials, products (including software) or
          other services included on or otherwise made available to you through
          the Lingua Fonica services, Lingua Fonica&apos;s servers or electronic
          communications sent from Lingua Fonica are free of viruses or other
          harmful components. To the full extent permissible by law, Lingua
          Fonica will not be liable for any damages of any kind arising from the
          use of any Lingua Fonica service, or from any information, content,
          materials, products (including software) or other services included on
          or otherwise made available to you through any Lingua Fonica service,
          including, but not limited to direct, indirect, incidental, punitive,
          and consequential damages, unless otherwise specified in writing.
        </Paragraph>
        <H2 subtitle="10. Disputes" subtitleAlignLeft />
        <Paragraph>
          Any dispute or claim relating in any way to your use of any Lingua
          Fonica Service will be adjudicated in the state or Federal courts in
          Wood County, Ohio, and you consent to exclusive jurisdiction and venue
          in these courts. All users waive any right to a jury trial.
        </Paragraph>
        <H2 subtitle="11. Indemnification" subtitleAlignLeft />
        <Paragraph>
          Students or Tutors shall reimburse, indemnify, defend and hold
          harmless Lingua Fonica, its officers, employees and independent
          contractors from and against any loss, expense, damage or injury
          suffered or sustained by Lingua Fonica, its officers, employees or
          independent contractors by reason of any acts or omissions arising out
          of such person&apos;s usage of the Services and/or website of Lingua
          Fonica, including, without limitation, any judgment, award,
          settlement, reasonable attorneys&apos; fees and other costs and
          expenses incurred in connection with the defense of any actual or
          threatened action, proceeding or claim.
        </Paragraph>
        <H2 subtitle="12. Contact information" subtitleAlignLeft />
        <Paragraph>info@linguafonica.com</Paragraph>
      </PageBody>
    </Layout>
  );
}
